<template>
  <Layout style="background-color:white;">
    <PageHeader :title="title" :items="items" />
    <div class="container-fluid">

      <div class="row">
        <div class="col-md-2">
          <b-form inline>
            <label class="sr-only" for="inline-form-input-name">Gönderilecek E-mail Kişi Adeti</label>
            <b-form-input
              style="width:100%"
              id="inline-form-input-name"
              class="mb-2 mr-sm-2 mb-sm-0"
              placeholder="Adet"
              v-model="mailgonderilecekadet"
            ></b-form-input>
          </b-form>
        </div>
        <div class="col-md-6">
            <b-button variant="primary" @click="EmailGonder">Gönder</b-button>
            <span class="ml-3">Gönderilmeyen E-mail Sayısı : {{ adet }}</span> 
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">

         <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Ara:
                    <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2">
                    </b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive">
              <b-table :items="transactionData" :fields="fields" responsive="sm" :per-page="perPage"
                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">

                <template v-slot:cell(updatemail)="data">

                  <b-alert v-if="data.item.updatemail==0" show variant="danger">E-mail Gönderilmedi</b-alert>
                  <b-alert v-else-if="data.item.updatemail==1" show variant="secondary">E-mail Gönderildi</b-alert>
                  
                </template>                
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>

     </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {axiosInstance} from '../../../helpers/axios';

export default {
  name: "mail-list",
  components: {
    Layout,
    PageHeader,

  },
  data() {
    return {
      title: "E-mail Listesi",
      items: [{
        text: "Anasayfa"
      },
        {
          text: "E-mail Listesi",
          active: true
        }
      ],
        adet:0,
        transactionData: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 15,
        pageOptions: [5, 10, 15, 25, 50],
        filter: null,
        filterOn: [],
        sortBy: "orderid",
        sortDesc: false,
        fields: [{
            key: "firmaadi",
            label: "Firma"
          },{
            key: "email",
            label: "E-mail"
          },{
            key: "updatemail",
            label: "E-mail Durumu"
          }
        ],
        mailgonderilecekadet:0,
    }
  },
  created() {
    var vm = this;
    vm.Musteriler();
  },
  methods:{
    EmailGonder()
    {
      var vm = this;
      try {

        axiosInstance.post("update/email-gonder",{
          adet:vm.mailgonderilecekadet,
        }).then((response)=>{

          console.log(response);

        }).catch((error)=>{
          vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
        })
        
      } catch (error) {

        vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
        
      }
    },
    Musteriler()
    {
      var vm = this;
         vm.transactionData = [];
         vm.adet = 0;
         try {

          axiosInstance.get("kullanicilar/satis/listele").then((response)=>{

           if (response.data.error == true) {
              vm.$bvToast.toast(response.data.message, {
                title: `Uyari !`,
                variant: "warning",
                solid: true
              });
           }else{
              if(response.data.data.length > 0)
              {
                 response.data.data.forEach(element => {
                    vm.transactionData.push({
                     email: element.email,
                     firmaadi:element.firmaadi, 
                     id:element.id,
                     updatemail:element.updatemail                     
                    })

                    if(element.updatemail==0)
                    {
                      vm.adet += 1;
                    }

                 });

              }

              
           }

           
          }).catch((error)=>{

            vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
            
          })
          
         } catch (error) {

          vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });

         }
    }
  }
}
</script>

<style scoped>

</style>