import { render, staticRenderFns } from "./mail.vue?vue&type=template&id=f082ab34&scoped=true&"
import script from "./mail.vue?vue&type=script&lang=js&"
export * from "./mail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f082ab34",
  null
  
)

export default component.exports